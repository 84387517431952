.popup__container {
  position: relative;
  width: calc(100% - 38px);
  max-width: 430px;
  padding: 25px 22px;
  background-color: var(--white);
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  box-sizing: border-box;
}

@media (min-width: 512px) {
  .popup__container {
    padding: 34px 36px 37px;
  }
}
