.popup__wrapper {
  position: relative;
  width: calc(100% - 38px);
  max-width: 430px;
  padding: 60px 36px;
  background-color: var(--white);
  box-shadow: 0px 0px 25px rgb(0 0 0 / 15%);
  border-radius: 10px;
  box-sizing: border-box;
}
