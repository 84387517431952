.card__button-like {
  display: block;
  width: 22px;
  height: 19px;
  margin-bottom: 3px;
  background-color: transparent;
  background-image: url(../../../images/icon-heart.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: none;
  transition: opacity .3s linear;
  cursor: pointer;
  flex-shrink: 0;
}

.card__button-like:hover {
  opacity: .5;
}
