.auth__wrapper {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}
