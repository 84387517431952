.header__logo {
  width: 104px;
  height: 24px;
}

@media (min-width: 768px) {
  .header__logo {
    width: 142px;
    height: 33px;
  }
}
