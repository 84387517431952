.popup__caption_place_info-tooltip {
  padding: 0;
  margin: 0;
  color: var(--black);
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  max-width: 358px;
}
