.burger__line {
  width: 24px;
  height: 3px;
  background-color: var(--white);
  position: relative;
}

.burger__line::before {
  position: absolute;
  content: "";
  top: -8px;
  left: 0;
  width: 24px;
  height: 3px;
  background-color: var(--white);
  transition: all 0.3s ease-out;
}

.burger__line::after {
  position: absolute;
  content: "";
  top: 8px;
  left: 0;
  width: 24px;
  height: 3px;
  background-color: var(--white);
  transition: all 0.3s ease-out;
}
