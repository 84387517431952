.popup__label:not(:last-of-type) {
  margin-bottom: 5px;
}
.popup__label:last-of-type {
  margin-bottom: 20px;
}

@media (min-width: 512px) {
  .popup__label:last-of-type {
    margin-bottom: 23px;
  }
}
