.profile__button-edit {
  display: block;
  width: 18px;
  height: 18px;
  background-color: transparent;
  background-image: url(../../../images/ico-edit.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 7.5px 7.5px;
  border: 1px solid var(--white);
  transition: opacity .3s linear;
  cursor: pointer;
  flex-shrink: 0;
  margin: 0 0 0 18px;
}

.profile__button-edit:hover {
  opacity: .6;
}

@media (min-width: 768px) {
  .profile__button-edit {
    width: 24px;
    height: 24px;
    margin: 0 18px;
    background-size: 10px 10px;
  }
}
