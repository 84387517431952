.popup__button-close {
  position: absolute;
  top: -36px;
  right: 0;
  display: block;
  width: 20px;
  height: 20px;
  background-color: transparent;
  background-image: url(../../../images/icon-close.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px;
  border: none;
  transition: opacity .3s linear;
  cursor: pointer;
}

.popup__button-close:hover {
  opacity: .6;
}

@media (min-width: 512px) {
  .popup__button-close {
    width: 32px;
    height: 32px;
    background-size: 32px;
    top: -40px;
    right: -40px;
  }
}
