.header__wrapper {
  position: absolute;
  top: -150px;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
  width: 100%;
  padding: 40px 0;
  background-color: var(--black);
  border-bottom: 1px solid var(--grey);
  box-sizing: border-box;
  transition: all 0.3s ease-out;
}

@media (min-width: 768px) {
  .header__wrapper {
    position: static;
    flex-direction: row;
    justify-content: end;
    gap: 24px;
    padding: 0;
    border-bottom: none;
  }
}
