.profile__name {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 27px;
  line-height: 33px;
  margin: 0 7px 0 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@media (min-width: 768px) {
  .profile__name {
    font-size: 42px;
    line-height: 48px;
  }
}
