.burger_close .burger__line {
  background-color: transparent;
}

.burger_close .burger__line::before {
  width: 28px;
  transform: rotate(45deg);
  top: 0;
}

.burger_close .burger__line::after {
  width: 28px;
  transform: rotate(-45deg);
  top: 0;
}
