.popup__input {
  width: 100%;
  padding: 10px 0;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: var(--black);
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.popup__input::placeholder {
  color: var(--black);
}
