.header__link {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: right;
  color: var(--white);
  text-decoration: none;
  transition: opacity .3s linear;
}

.header__link:hover {
  opacity: 0.6;
}
