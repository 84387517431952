.profile__button-add {
  display: block;
  width: 100%;
  height: 50px;
  background-color: transparent;
  background-image: url(../../../images/ico-add.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 16px;
  border: 2px solid var(--white);
  border-radius: 2px;
  transition: opacity .3s linear;
  cursor: pointer;
}

.profile__button-add:hover {
  opacity: .6;
}

@media (min-width: 768px) {
  .profile__button-add {
    width: 150px;
    margin-left: auto;
    background-size: 22px;
  }
}
