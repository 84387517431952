.logo {
  display: block;
  width: 103.74px;
  height: 24.4px;
}

@media (min-width: 768px) {
  .logo {
    width: 142px;
    height: 33px;
  }
}
