.container {
  position: relative;
  max-width: 880px;
  margin: 0 auto;
  width: calc(100% - 38px);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

@media (min-width: 768px) {
  .container {
    width: calc(100% - 56px);
  }
}

@media (min-width: 1280px) {
  .container {
    width: calc(100% - 400px);
  }
}
