.body {
  font-family: 'Inter', ‘Arial’, sans-serif;
  font-size: 27px;
  font-weight: 400;
  font-style: normal;
  line-height: 33px;
  color: var(--white);
  background-color: var(--black);
  text-rendering: optimizeLegibility;
}
