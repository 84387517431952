.profile__avatar {
  position: relative;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  margin: 0 auto 26px;
  background-color: transparent;
  background-image: url(../../../images/kusto-sourse.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.profile__avatar:hover::before {
  content:'';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  background-image: url(../../../images/ico-edit.svg);
  background-size: 26px;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}

@media (min-width: 768px) {
  .profile__avatar {
    margin: 0;
    flex-shrink: 0;
  }
}
