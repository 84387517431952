.header__button-exit {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: right;
  color: var(--light-grey);

  display: block;
  /*width: 100%;*/
  /*height: 50px;*/
  background-color: transparent;
  border: none;
  transition: opacity .3s linear;
  cursor: pointer;
}

.header__button-exit:hover {
  opacity: .6;
}
